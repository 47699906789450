<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <a-drawer :title="title" width="50%" :closable="false" :visible="visible" @close="onClose(false)" v-if="visible">
    <div>已选择角色</div>
    <div>
      <a-tag v-for="item in listDataRole" :key="item" color="pink">{{item.name}}</a-tag>
    </div>
    <div>
      <a-table :columns="[sortColumn,...columns]" :pagination="pagination" :row-key="record => record.id" :data-source="listData" :loading="loading">
        <template #sort="{ index }">
          {{index+1}}
        </template>

        <template #operation="{ record }">
          <span>
            <a-popconfirm v-if="record.selected" title="确定取消?" @confirm="changeAuth(record)">
              <a>已选择</a>
            </a-popconfirm>
            <a-popconfirm v-else title="确定选择?" @confirm="changeAuth(record)">
              <a>选择</a>
            </a-popconfirm>
          </span>
        </template>
      </a-table>
    </div>
  </a-drawer>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { updateDataRole, dataRole } from '@/api/system/employee'
import { page as rolePage } from '@/api/system/role'
import { message } from 'ant-design-vue'
export default {
  computed: {
  },

  setup () {
    const state = reactive({
      listData: [],
      listDataRole: [],
      empId: '',
      form: {},
      authValue: '',
      visible: false,
      loading: false,
      pagination: {
        pageSize: 20,
        showSizeChanger: true,
        pageSizeOptions: ['20', '30', '40'],
        showTotal: (total, range) => `共 ${total} 条`,
        onChange: (page, pageSize) => {
          state.pagination.current = page
          state.pagination.pageSize = pageSize
          loadData(state.empId)
        }
      },
      sortColumn: { title: '序号', slots: { customRender: 'sort' } },
      columns: [
        {
          title: '角色名称',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 400,
          slots: {
            customRender: 'operation'
          }
        }
      ]
    })
    const loadData = async (id) => {
      state.empId = id
      state.loading = true
      rolePage({
        ...state.pagination
      }).then((res) => {
        state.listData = res.data.records
        state.pagination.current = res.data.current
        state.pagination.pageSize = res.data.size
        state.pagination.total = res.data.total
        dataRole(state.empId).then((res) => {
          state.listDataRole = res.data
          state.listData.forEach(d => {
            state.listDataRole.forEach(r => {
              if (r.roleId === d.id) {
                d.selected = 1
              }
            })
          })
        })
      })
      state.loading = false
    }
    const onClose = (recode) => {
      state.visible = recode
    }

    const changeAuth = (record) => {
      state.form = {
        roleId: record.id,
        empId: state.empId
      }
      // 保存单次权限关系
      updateDataRole(state.empId, state.form).then((res) => {
        if (res.code === 10000) {
          if (record.selected) {
            message.success('取消角色成功')
            state.listDataRole.forEach((r, i) => {
              if (r.roleId === record.id) {
                state.listDataRole.splice(i, 1)
              }
            })
          } else {
            message.success('绑定角色成功')
            state.listDataRole.push({ id: record.authId, name: record.name, roleId: record.id })
          }
          if (record.authId === null || record.authId === '') {
            record.authId = res.data
          }
          record.selected = record.selected === 1 ? 0 : 1
        }
      })
    }
    return {
      loadData,
      ...toRefs(state),
      onclick,
      onClose,
      changeAuth
    }
  }
}
</script>
