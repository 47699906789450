<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <suy-table v-model:columns="columns" :reload="loadData">
    <!-- 查询模块 -->
    <template #search>
      <a-form ref="formQueryRef" layout="inline" :model="searchForm">
        <a-form-item label="">
          <a-input v-model:value="searchForm.name" placeholder="员工名称" />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.no" placeholder="工号" />
        </a-form-item>
        <!-- <a-form-item label="部门" style="width:174px">
          <a-select v-model:value="searchForm.orgId" show-search :showSearch='true' :filter-option="true"
            option-filter-prop="children" :not-found-content="null">
            <a-select-option v-for="parentOrg in orgList" :key="parentOrg.id">
              {{ parentOrg.name }}
            </a-select-option>
          </a-select>
        </a-form-item> -->
        <a-form-item label="">
          <a-tree-select show-search style="width: 200px" :treeDefaultExpandAll="false" placeholder="部门"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" allow-clear tree-default-expand-all
            @change="chooseOrg">
            <a-tree-select-node v-for="item in orgList" :key="item.id" :value="item.name + ',' + item.id"
              :title="item.name">
              <a-tree-select-node v-for="v in item.treeList" :key="v.id" :value="v.name + ',' + v.id" :title="v.name">
              </a-tree-select-node>
            </a-tree-select-node>
          </a-tree-select>
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="searchForm.isQuit" style="width: 120px" placeholder="岗位状态" allowClear>
            <a-select-option :value="0">在职</a-select-option>
            <a-select-option :value="1">离职</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-space>
            <a-button type="primary" @click="searchData" :loading="loading">
              <template #icon>
                <SearchOutlined />
              </template>查询
            </a-button>
            <a-button @click="resetData" :loading="loading">
              重置
            </a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </template>
    <!-- 新增按钮 -->
    <template #toolbarRight>
      <a-button type="primary" @click="add">
        <template #icon>
          <PlusCircleOutlined />
        </template>
        新增
      </a-button>
    </template>
    <!-- 表格主体 -->
    <a-table :columns="[sortColumn, ...columns]" :row-key="record => record.id" :data-source="listData"
      :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #sort="{ index }">
        {{ index + 1 }}
      </template>
      <!-- 表格内容--头像 -->
      <!-- <template #avatarFileUrl="{record}">
        <a-avatar :src="`${record.avatarFileUrl}`" :srcset="`${record.avatarFileUrl}`" size="large" style="color: #007bff;font-size: large;">{{record.name.slice(0,1)}}</a-avatar>
                  </template> -->
      <template #quit="{ record, index }">
        <a-popconfirm :title="`你确定${record.isEnable ? '禁用' : '启用'}该员工吗？`" @confirm="adjustment(index)">
          <a-switch v-model:checked="record.isEnable" checkedChildren="启用" unCheckedChildren="禁用"
            @click="record.isEnable = !record.isEnable" />
        </a-popconfirm>
      </template>
      <template #operation="{ record }">
        <span>
          <a @click="role(record.id)">角色</a>
          <a-divider type="vertical" />
          <a @click="auth(record.id)">权限</a>
          <a-divider type="vertical" />
          <a @click="edit(record.id)">编辑</a>
          <a-divider type="vertical" />
          <a @click="position(record.id)">职务</a>
          <a-divider type="vertical" />
          <a @click="onEmployeModal(record)">离职</a>
          <a-divider type="vertical" />
          <!-- <a-popconfirm v-if="listData.length"
                        title="确定删除?"
                        @confirm="deleteItem(record.id)">
            <a>删除</a>
                      </a-popconfirm>-->
        </span>
      </template>
    </a-table>
  </suy-table>
  <!-- 新增 功能模态框 -->
  <a-drawer width="600" v-model:visible="drawerVisible" :title="drawerTitle" :body-style="{ height: '100vh' }"
    destroyOnClose>
    <Add ref="formRef" v-model:value="form" />
    <div :style="{
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '10px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 1,
    }">
      <a-button style="margin-right: 8px" @click="reset">重置</a-button>
      <a-button type="primary" :loading="confirmLoading" @click="save">保存</a-button>
    </div>
  </a-drawer>
  <!-- 权限模块组件 -->
  <DataAuth ref="DataAuth" />
  <!-- 角色模块组件 -->
  <DataRole ref="DataRole" />
  <a-drawer v-model:visible="positionVisible" title="职务管理" :width="1000" destroyOnClose>
    <DataPosition :empId="empId" />
  </a-drawer>
  <a-modal v-model:visible="employeModal" width="45%" title="离职情况" @ok="quit(employe.id)">
    <a-row :gutter="2">
      <a-col :span="12">
        <a-form-item label="离职类型">
          <a-select v-model:value="employe.isQuit" class="ransport_search_input" allowClear>
            <a-select-option :value="1">离职</a-select-option>
            <a-select-option :value="0">在职</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="离职时间">
          <a-date-picker v-model:value="employe.orderTimeStart" valueFormat="YYYY-MM-DD" :format="dateFormat" />
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="备注">
          <a-textarea v-model:value="employe.remark" class="ransport_textarea" :maxlength="200" showCount />
        </a-form-item>
      </a-col>
    </a-row>
  </a-modal>
</template>
<script>
import { page, add as addItem, edit as editItem, detail, enable, isQuit } from '@/api/system/employee'
import { page as orgListPage } from '@/api/system/organzition'
import { typeAssignment } from '@/utils/validate'
import { getOrgList, getOrgTreeList } from '@/api/global'
import { toRefs, reactive, onMounted, ref } from 'vue'
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import SuyTable from '@/components/SuyTable'
import Add from './add'
import DataAuth from './dataAuth'
import DataRole from './dataRole'
import DataPosition from './dataPosition'
export default {
  name: 'employee',
  setup () {
    const DataAuth = ref(null)
    const DataRole = ref(null)
    const formRef = ref()

    const state = reactive({
      employeTypes: [['离职', 1], ['在职', 0]],
      DataPosition: null,
      positionVisible: false,
      loading: false,
      employeModal: false,
      empId: undefined,
      employe: {
        id: '',
        isQuit: null,
        remark: '',
        orderTimeStart: ''
      },
      orgList: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      searchForm: {
        name: null,
        no: null,
        isQuit: null,
        orgId: null
      },
      mirrorSearchForm: {},
      listData: [],
      // 表格静态框架数据
      sortColumn: { title: '序号', slots: { customRender: 'sort' } },
      columns: [
        {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
          fixed: 'left'
        },
        // {
        //   title: '头像',
        //   dataIndex: 'avatarFileUrl',
        //   slots: {
        //     customRender: 'avatarFileUrl'
        //   }
        // },
        {
          title: '性别',
          dataIndex: 'genderType.label'
        },
        {
          title: '联系方式',
          dataIndex: 'mobile'
        },
        {
          title: '工号',
          dataIndex: 'no'
        },
        {
          title: '部门',
          dataIndex: 'orgName'
        },
        {
          title: '职务',
          dataIndex: 'positionName'
        },
        {
          title: '入职时间',
          dataIndex: 'entryDate'
        },
        {
          title: '岗位状态',
          dataIndex: 'isQuit.label'
        },
        {
          title: '状态',
          dataIndex: 'isEnable',
          slots: {
            customRender: 'quit'
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 300,
          slots: {
            customRender: 'operation'
          },
          fixed: 'right'
        }
      ],
      drawerVisible: false,
      drawerTitle: '',
      confirmLoading: false,
      form: {},
      rules: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }]
      }
    })

    // 表格动态数据
    const loadData = async (type) => {
      state.loading = true
      const { data, data: { records } } = await page({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      })
      if (type) {
        state.pagination.current = 1
        state.listData = []
      }
      setTimeout(() => {
        state.listData = records.map(item => {
          return { ...item, isEnable: !!item.isEnable.value }
        })
      }, 10)
      state.pagination.total = data.total
      state.loading = false
    }
    onMounted(() => {
      loadData()
      getOrgListAPI()
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })

    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    getOrgList({}).then(res => { state.orgList = res.data })
    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }
    const resetData = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      searchData()
    }

    const add = () => {
      state.form = {
        birthday: '',
        email: '',
        entryDate: '',
        genderType: null,
        idNumber: '',
        isEnable: null,
        isQuit: null,
        mobile: '',
        name: '',
        no: '',
        orgId: '',
        positionId: '',
        quitDate: '',
        remark: '',
        username: ''
      }
      state.drawerTitle = '新增员工'
      state.drawerVisible = true
    }
    const getOrgListAPI = () => {
      getOrgTreeList().then(res => {
        if (res.code === 10000) {
          state.orgList = res.data
        }
      })
    }
    const chooseOrg = (e, v) => {
      if (e) state.searchForm.orgId = e.split(',')[1]
      else state.searchForm.orgId = ''
    }
    const auth = (id) => {
      DataAuth.value.onClose(true)
      DataAuth.value.loadData(id)
    }

    const role = (id) => {
      DataRole.value.onClose(true)
      DataRole.value.loadData(id)
    }
    const position = (id) => {
      state.empId = id
      state.positionVisible = true
    }
    const onEmployeModal = value => {
      state.employeModal = true
      state.employe.id = value.id
      state.employe.isQuit = 1
    }

    const edit = async id => {
      const { data } = await detail(id, {})
      state.form = {
        ...data,
        genderType: typeAssignment(data.genderType),
        isEnable: typeAssignment(data.isEnable),
        isQuit: typeAssignment(data.isQuit)
      }
      state.drawerTitle = '编辑员工'
      state.drawerVisible = true
      console.log(data)
    }
    const quit = async id => {
      isQuit(state.employe.id, state.employe).then(res => {
        if (res.code === 10000) {
          state.employeModal = false
          message.success(res.msg)
          state.employe = {
            id: '',
            type: 0,
            remark: '',
            orderTimeStart: ''
          }
          loadData(1)
        }
      })
    }

    const reset = () => {
      formRef.value
        .formRef.resetFields()
    }

    const save = () => {
      formRef.value
        .formRef
        .validate()
        .then(() => {
          state.confirmLoading = true
          if (state.form.id) {
            editItem({ employee: { ...state.form } }).then(res => {
              if (res.code === 10000) {
                message.success(res.msg) // 提交状态返回信息
                state.drawerVisible = false
                // console.log(res)  // 编辑功能的返回数据
                loadData()
              }
            }).catch(err => {
              console.log(err)
            })
          } else {
            addItem({ employee: { ...state.form } }).then(res => {
              if (res.code === 10000) {
                message.success(res.msg)
                state.drawerVisible = false
                loadData()
              }
            }).catch(err => {
              console.log(err)
            })
          }
          state.confirmLoading = false
        })
        .catch(error => {
          console.log(error)
        })
    }

    const deleteItem = (id) => {

    }

    const adjustment = index => {
      enable(state.listData[index].id, Number(!state.listData[index].isEnable)).then(res => {
        if (res.code === 10000) {
          state.listData[index].isEnable = !state.listData[index].isEnable
          message.success(res.msg)
        }
      })
    }
    const filterOption = value => {

    }
    const onLoadData = () => {

    }
    return {
      ...toRefs(state),
      onLoadData,
      loadData,
      filterOption,
      quit,
      chooseOrg,
      getOrgListAPI,
      searchData,
      resetData,
      handleTableChange,
      add,
      edit,
      reset,
      save,
      formRef,
      deleteItem,
      DataAuth,
      onEmployeModal,
      DataRole,
      position,
      auth,
      role,
      adjustment
    }
  },
  components: {
    SuyTable,
    Add,
    PlusCircleOutlined,
    SearchOutlined,
    DataAuth,
    DataRole,
    DataPosition
  }
}
</script>
