import { postBodyRequest, getRequest } from '@/utils/axios'

export function page (params) {
  return postBodyRequest('/cis/role/page', params)
}

export function edit (params) {
  return postBodyRequest('/cis/role/edit', params)
}

export function add (params) {
  return postBodyRequest('/cis/role/add', params)
}

export function resouceTree (params) {
  return getRequest('/cis/role/resource/tree', params)
}

export function grantResource (params) {
  return postBodyRequest('/cis/role/grant/resource', params)
}

export function getResourceIds (roleId,params) {
  return getRequest(`/cis/role/${roleId}/resource`, params)
}
