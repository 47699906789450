<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <a-button class="editable-add-btn" @click="handleAdd" style="margin-bottom: 8px">新增</a-button>
  <a-card :bodyStyle="{padding:0}" v-for="(item, index) in listData" :key="item">
    <a-form layout="vertical">
      <a-row :gutter="2">
        <a-col :span="2">
          <a-form-item label="序号">
            {{index+1}}
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="所属职务">
            <a-select v-model:value="item.positionId">
              <a-select-option v-for="position in positionList" :key="position.id" :value="position.id">{{position.name}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="所属机构">
            <a-select mode="multiple" v-model:value="item.orgIds">
              <a-select-option v-for="org in orgList" :key="org.id" :value="org.id">{{org.name}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item label="操作">
            <span>
              <a-popconfirm title="确定保存?" @confirm="saveDutyPosition(item, index)">
                <a>保存</a>
              </a-popconfirm>
              <a-divider type="vertical" />
              <a-popconfirm title="确定删除?" @confirm="deleteDutyPosition(item, index)">
                <a>删除</a>
              </a-popconfirm>
            </span>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-card>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { updateDataPosition, dataPosition, deleteDataPosition } from '@/api/system/employee'
import { message } from 'ant-design-vue'
import { getOrgList, getPositionList } from '@/api/global'
export default {
  props: {
    empId: { type: String, default: '' }
  },
  setup (props) {
    const state = reactive({
      listData: [],
      empId: props.empId,
      orgList: [],
      positionList: []
    })
    const loadData = () => {
      state.loading = true
      dataPosition(state.empId).then((res) => {
        state.listData = res.data
      })
      state.loading = false
    }
    loadData()
    const onClose = (recode) => {
      state.visible = recode
    }
    const handleAdd = () => {
      state.listData.push({ id: null, dutyId: null, orgId: null, empId: state.empId })
    }
    const deleteDutyPosition = (item, index) => {
      if (item.id !== null) {
        deleteDataPosition(item.id).then((res) => {
          if (res.code === 10000) {
            message.info('删除成功')
          } else {
            message.info(res.msg)
          }
        })
      }
      state.listData.splice(index, 1)
    }
    const saveDutyPosition = (item, index) => {
      const empPosition = {
        id: item.id,
        orgIds: item.orgIds,
        positionId: item.positionId,
        empId: state.empId
      }
      // 保存
      updateDataPosition(state.empId, empPosition).then((res) => {
        if (res.code === 10000) {
          if (res.data === null) {
            message.info('删除成功')
          } else {
            message.info('保存成功')
          }
          item.id = res.data
        }
      })
    }
    getOrgList({}).then(res => { state.orgList = res.data })
    getPositionList({}).then(res => { state.positionList = res.data })
    return {
      loadData,
      ...toRefs(state),
      onclick,
      onClose,
      handleAdd,
      deleteDutyPosition,
      saveDutyPosition
    }
  }
}
</script>
