<template>
  <a-form ref="formRef" layout="vertical" :model="form" :rules="rules">

    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-item label="姓名" name="name">
          <a-input v-model:value="form.name" />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="性别">
          <a-select v-model:value="form.genderType">
            <a-select-option :value=1>男</a-select-option>
            <a-select-option :value=2>女</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-item label="生日">
          <a-date-picker v-model:value="form.birthday" placeholder="请选择出生日期" style="width:100%" />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="邮箱">
          <a-input v-model:value="form.email" />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-item label="身份证号" name="idNumber">
          <a-input v-model:value="form.idNumber" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item label="电话号码" name="mobile">
          <a-input v-model:value="form.mobile" />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row :gutter="{ xs: 8, sm: 16, md: 24}">
      <a-col :span="12">
        <a-form-item label="入职时间">
          <a-date-picker v-model:value="form.entryDate" placeholder="请选择入职时间" style="width:100%" />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="工号" name="no">
          <a-input v-model:value="form.no" />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-item label="所属机构" name="orgId">
          <a-select v-model:value="form.orgId">
            <a-select-option v-for="item in orgList" :key="item.id">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item label="所属职务" name="positionId">
          <a-select v-model:value="form.positionId">
            <a-select-option v-for="item in positionList" :key="item.id">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row :gutter="16">
      <a-col :span="24">
        <a-form-item label="备注">
          <a-textarea v-model:value="form.remark" :rows="4" :maxlength="200" showCount />
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>
<script>
import { computed, reactive, ref, toRefs, watch } from 'vue'
import { mobileValidate, idNumberValidate } from '@/utils/validate'
import { getOrgList, getPositionList } from '@/api/global'
export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          name: '',
          mobile: '',
          certificateNumber: '',
          supplierId: '',
          address: '',
          remark: '',
          businessType: null,
          customerType: null,
          settlementMode: null
        }
      }
    }
  },
  setup (props, { emit }) {
    const formRef = ref()
    const state = reactive({
      form: computed(() => props.value),
      rules: {
        name: [{ required: true, message: '请输入 姓名', trigger: 'blur' }],
        genderType: [{ required: true, message: '请选择性别', trigger: 'change', type: 'number' }],
        birthday: [{ required: true, message: '请选择生日', trigger: 'change', type: 'date' }],
        email: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
        idNumber: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          { pattern: idNumberValidate, message: '请输入正确的身份证号', trigger: 'blur' }],
        mobile: [
          { required: true, message: '请输入电话号码', trigger: 'blur' },
          { pattern: mobileValidate, message: '请输入正确的电话号码', trigger: 'blur' }
        ],
        entryDate: [{ required: true, message: '请选择入职时间', trigger: 'change', type: 'date' }],
        no: [{ required: true, message: '请输入工号', trigger: 'blur' }],
        orgId: [{ required: true, message: '请选择机构', trigger: 'change' }],
        positionId: [{ required: true, message: '请选择职务', trigger: 'change' }]
      },
      positionList: [],
      orgList: [],
      settlementModes: [
        { label: '货到付款', value: 1 },
        { label: '起运地付清', value: 2 },
        { label: '预付X到付X', value: 3 },
        { label: '月结', value: 4 },
        { label: '记账', value: 5 }
      ]
    })

    watch(() => state.form, (newValue, oldValue) => {
      emit('update:value', newValue)
    }, { deep: true })

    getOrgList({}).then(res => { state.orgList = res.data })
    getPositionList({}).then(res => { state.positionList = res.data })
    return {
      formRef,
      ...toRefs(state)
    }
  }
}
</script>
